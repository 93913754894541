'use strict';

import {
  setHeight,
  shrink,
  setCoordinates,
  getHeightDiff,
} from './adjust-teaser.js';
import { layouts } from './layout-definitions.js';

class Container {
  constructor(options) {
    this.freeSpace = [];
    this.content = [];
    this.type = options.type;
    this.ltr = options.ltr;
    this.vPadding = options.vPadding;
    this.hPadding = options.hPadding;
    this.compositions = options.compositions || [];
    this.index = options.index || 0;
    this.maxHeight = {};
  }

  addMaxHeight(left, max) {
    if (this.maxHeight[left]) {
      this.maxHeight[left] += max;
    } else {
      this.maxHeight[left] = max;
    }
  }

  getMaxHeight(left) {
    return Object.keys(this.maxHeight).reduce((result, key) => {
      key = parseInt(key, 10);
      if (left !== key && result < this.maxHeight[key]) {
        result = this.maxHeight[key];
      }
      return result;
    }, 0);
  }

  updateFree(rect) {
    // The algorithm works by dividing unused space inside the container into rectangles.
    // When we insert a new teaser in the container we check if it fits inside one
    // of the free space rectangles
    // If it does, we insert the teaser and update the free space rectangles
    // If it doesnt fit we close the container and create a new container
    // We then try to fill the remaining free sapce rectangles
    // altering the width of the teasers and the image crops
    if (this.content.length === 0) {
      return;
    }

    // Get last teaser
    const teaserWrapper = this.content[this.content.length - 1];
    const teaserInfo = teaserWrapper.teaser.getInfo();

    this.addMaxHeight(teaserWrapper.left, teaserInfo.maxHeight || 0);

    if (rect === undefined) {
      const height = this.forceSecondTeaser
        ? 10000
        : teaserWrapper.height + teaserWrapper.height * 0.25;

      this.freeSpace.push({
        width: this.type - teaserWrapper.width - this.hPadding,
        height,
        top: 0,
        left: teaserWrapper.width + this.hPadding,
      });
      this.freeSpace = this.freeSpace.filter(
        (f) => f.width > 0 && f.height > 0
      );
      return;
    }

    // remove old free space
    this.freeSpace.splice(this.freeSpace.indexOf(rect), 1);

    let rectHeight = rect.height;
    if (this.content.length === 2) {
      const firstTeaserWrapper = this.content[this.content.length - 2];
      rectHeight =
        firstTeaserWrapper.height > teaserWrapper.height
          ? firstTeaserWrapper.height
          : teaserWrapper.height;
    }

    if (teaserWrapper.height < rectHeight) {
      this.freeSpace.push({
        width: teaserWrapper.width,
        height: rectHeight - teaserWrapper.height - this.vPadding,
        top: rect.top + teaserWrapper.height + this.vPadding,
        left: rect.left,
      });
    }

    if (teaserWrapper.width < rect.width) {
      this.freeSpace.push({
        width: rect.width - teaserWrapper.width - this.hPadding,
        height: rectHeight,
        top: rect.top,
        left: rect.left + teaserWrapper.width + this.hPadding,
      });
    }

    this.freeSpace = this.freeSpace
      .filter((f) => f.width > 0 && f.height > 0)
      .sort((a, b) => a.top - b.top);
  }

  getColumns() {
    let columns = this.content.reduce((pv, teaserWrapper) => {
      pv[teaserWrapper.left] = pv[teaserWrapper.left] || [];
      pv[teaserWrapper.left].push(teaserWrapper);
      return pv;
    }, {});

    // Sort teasers in columns
    columns = Object.keys(columns)
      .sort()
      .map((key) => columns[key].sort((a, b) => a.top - b.top));

    return columns;
  }

  fill() {
    // Ensure correct breakpoint
    this.content.forEach((teaserWrapper) =>
      teaserWrapper.teaser.setActiveBreakpoint(this.type)
    );

    // Find columns
    let columns = this.getColumns();

    // Find any horizontal free space
    if (columns.length === 1) {
      columns[0].forEach((teaserWrapper) =>
        layouts[this.type].chooseVersion(
          teaserWrapper.teaser,
          this.compositions,
          true,
          this.index
        )
      );
    }
    this.freeSpace
      .filter((r) => r.top === 0)
      .forEach((freeSpace) => {
        let remainingWidth = freeSpace.width + this.hPadding;
        columns
          .slice(0)
          .reverse()
          .every((column) => {
            let top = 0;
            const newWidth = column[0].width + remainingWidth;

            column.forEach((teaserWrapper) => {
              const { teaser } = teaserWrapper;
              teaser.setWidth(
                [newWidth, 'largest', teaserWrapper.width],
                newWidth
              );
              const teaserInfo = teaser.getInfo();
              teaserWrapper.width = teaserInfo.width;
              teaserWrapper.height = teaserInfo.height;
              teaserWrapper.top = top;
              top += teaserInfo.height + this.vPadding;
            });
            remainingWidth = newWidth - column[0].width + this.hPadding;

            return remainingWidth > 0;
          });
      });

    if (columns.length > 1) {
      setHeight(
        columns,
        this.vPadding,
        layouts[this.type].getAvailableSizes(this.index),
        this.firstContainer
      );
      shrink(columns.length - 1, columns, this.vPadding);
      const diff = getHeightDiff(columns, this.vPadding, true);
      if (diff < layouts[this.type].breakOnDiff) {
        let top = 0;
        columns = [
          columns.reduce(
            (pv, column) =>
              pv.concat(
                column.map((teaserWrapper) => {
                  const { teaser } = teaserWrapper;
                  teaser.setVersion('imageLeft');
                  teaser.setWidth([this.type, 'largest']);
                  const teaserInfo = teaser.getInfo();
                  teaserWrapper.width = teaserInfo.width;
                  teaserWrapper.height = teaserInfo.height;
                  teaserWrapper.top = top;
                  teaserWrapper.left = 0;
                  top += teaserInfo.height + this.vPadding;
                  return teaserWrapper;
                })
              ),
            []
          ),
        ];
      }
    } else {
      columns[0].forEach((teaserWrapper) => {
        teaserWrapper.teaser.setMinImageHeight();
        teaserWrapper.teaser.setMaxImageHeight();
      });
    }

    setCoordinates(columns, this.ltr, this.vPadding, this.hPadding);

    const compositions = this.getColumns().map(
      (column) =>
        `${column[0].width}-${
          column[0].teaser.activeBreakpoint
            ? column[0].teaser.activeBreakpoint.active.version
            : 'default'
        }`
    );

    this.compositions.push(compositions);
    return {
      compositions,
      columns: this.getColumns(),
      width: this.type,
      content: this.content,
    };
  }

  add(teaser, groupType, retry) {
    if (this.open === false) {
      return false;
    }

    teaser.setActiveBreakpoint(this.type);

    if (this.content.length === 0) {
      const sizes = layouts[this.type].getInitialTeaserSize(
        teaser,
        this.compositions
      );
      teaser.setWidth(sizes);
      const teaserInfo = teaser.getInfo();
      this.content.push({
        left: 0,
        top: 0,
        width: teaserInfo.width,
        height: teaserInfo.height,
        teaser,
      });

      if (teaser.firstTeaser) {
        this.firstContainer = true;
      }

      this.forceSecondTeaser = !teaser.hasSize(this.type);

      this.updateFree();
      return true;
    }

    layouts[this.type].chooseVersion(
      teaser,
      this.compositions,
      false,
      this.index,
      groupType
    );

    const foundFreeSpace = !this.freeSpace
      .sort((r1, r2) => r2.top - r1.top)
      .every((rect) => {
        const availableHeight = rect.height + this.getMaxHeight(rect.left);
        if (teaser.setWidthBestFit(rect.width, availableHeight, rect.top > 0)) {
          const teaserInfo = teaser.getInfo();

          this.content.push({
            left: rect.left,
            top: rect.top,
            width: teaserInfo.width,
            height: teaserInfo.height,
            teaser,
          });
          this.updateFree(rect);

          return false;
        }
        return true;
      });

    // Second item is to wide for empty space. Try decreasing the first item
    if (!foundFreeSpace && !retry && this.content.length === 1) {
      const firstTeaser = this.content[0];
      if (!firstTeaser) {
        this.open = false;
        return this.open;
      }
      const remainingWidth = this.type - firstTeaser.width - this.hPadding;
      if (remainingWidth > 0 && !teaser.hasSize(remainingWidth)) {
        const targetSize = teaser.getSmallestSize(remainingWidth);
        firstTeaser.teaser.setActiveBreakpoint(this.type);
        if (
          targetSize &&
          firstTeaser.teaser.hasSize(
            this.type - targetSize.width - this.hPadding
          )
        ) {
          this.freeSpace = [];
          firstTeaser.teaser.setWidth([
            this.type - targetSize.width - this.hPadding,
          ]);
          const firstTeaserInfo = firstTeaser.teaser.getInfo();
          firstTeaser.width = firstTeaserInfo.width;
          firstTeaser.height = firstTeaserInfo.height;
          this.updateFree();
          return this.add(teaser, groupType, true);
        }
        this.open = false;
        return false;
      }
    }

    this.open = foundFreeSpace;

    return this.open;
  }

  getHeight() {
    return this.content.reduce((containerHeight, containerItem) => {
      const { top } = containerItem;
      const { height } = containerItem.teaser.getInfo();

      if (top + height > containerHeight) {
        return top + height;
      }

      return containerHeight;
    }, 0);
  }
}

export { Container };
