class PersonalisedDataExperiment {
  constructor(data) {
    if (!data) {
      data = [];
    }
    this.teaserData = this.filterMetaTeasers(data);
    this.personalizedTeasers = this.getPersonalizedTeasers(data);
    this.topListTeasers = this.personalizedTeasers?.filter(
      (el) => el.source === 'toplist'
    );
    this.boostTeasers = this.personalizedTeasers?.filter((el) =>
      el.source.includes('-importance')
    );
    this.sdsTeasers = this.personalizedTeasers?.filter(
      (el) => el.source === 'sds'
    );
    this.tracerRequestId = this.getTracerRequestId(data);
    this.setTeaserTracerMarkup();
  }

  filterMetaTeasers(data) {
    return data.filter(
      (el) => el && !el.personalised_content && !el.tracer_request_id
    );
  }

  getPersonalizedTeasers(data) {
    return (
      data
        .filter(
          (el) =>
            el && el.personalised_content && el.personalised_content.length > 0
        )
        .map((el) => el.personalised_content)[0] || []
    );
  }

  getTracerRequestId(data) {
    let tracerRequestId = '';
    data.forEach((el) => {
      if (el?.tracer_request_id) {
        tracerRequestId = el.tracer_request_id;
      }
    });
    return tracerRequestId;
  }

  getPageTracerMarkup() {
    const itemscope = document.createElement('div');
    itemscope.setAttribute('id', 'ordinoTracer');

    if (
      this.tracerRequestId ||
      this.topListTeasers ||
      this.boostTeasers ||
      this.sdsTeasers
    ) {
      itemscope.setAttribute('itemscope', '');
      itemscope.setAttribute(
        'itemtype',
        'https://www.adplogger.no/json-schema/CustomElement'
      );

      const itemPropTracer = document.createElement('meta');
      itemPropTracer.setAttribute('itemprop', 'custom-element#data');
      const tracerObject = {};
      Object.assign(
        tracerObject,
        this.tracerRequestId ? { tracer_request_id: this.tracerRequestId } : {}
      );
      Object.assign(
        tracerObject,
        this.topListTeasers ? { personalized_toplist: this.topListTeasers } : {}
      );
      Object.assign(
        tracerObject,
        this.boostTeasers ? { personalized_boost: this.boostTeasers } : {}
      );
      Object.assign(
        tracerObject,
        this.sdsTeasers ? { personalized_sds: this.sdsTeasers } : {}
      );

      itemPropTracer.setAttribute('content', `${JSON.stringify(tracerObject)}`);

      const itemPropName = document.createElement('meta');
      itemPropName.setAttribute('itemprop', 'custom-element#name');
      itemPropName.setAttribute('content', 'ordino_front_feed');

      itemscope.appendChild(itemPropTracer);
      itemscope.appendChild(itemPropName);
    }
    return itemscope;
  }

  addItemPropToHTML(html, itemProp, content) {
    return html.replace(
      '<meta',
      `<meta itemprop="${itemProp}" content="${content}" /><meta`
    );
  }

  setTeaserTracerMarkup() {
    this.topListTeasers?.forEach((el) => {
      if (this.teaserData[el.position.to].html) {
        this.teaserData[el.position.to].html = this.addItemPropToHTML(
          this.teaserData[el.position.to].html,
          'productFeature',
          'personalized_toplist'
        );
      }
    });
    this.boostTeasers?.forEach((el) => {
      if (this.teaserData[el.position.to].html) {
        this.teaserData[el.position.to].html = this.addItemPropToHTML(
          this.teaserData[el.position.to].html,
          'productFeature',
          'personalized_boost'
        );
      }
    });
    this.sdsTeasers?.forEach((el) => {
      if (this.teaserData[el.position.to].html) {
        this.teaserData[el.position.to].html = this.addItemPropToHTML(
          this.teaserData[el.position.to].html,
          'productFeature',
          'personalized_sds'
        );
      }
    });
  }
}

export { PersonalisedDataExperiment };
