'use strict';

import cssmq from 'css-mediaquery';

import debug from './log.js';

const mediaqueryPattern = /^@media\s+([^{]+)/;

const defaultBreakpoints = {
  version: 'default',
  layout: [],
};

const defaultVersion = {
  height: 0,
  fontSize: 1,
};

function sortTeasers(a, b) {
  const s = b.width - a.width;
  if (s === 0) {
    return b.height - a.height;
  }
  return s;
}

function sortTeasersAsc(a, b) {
  const s = a.width - b.width;

  if (s === 0) {
    return b.height - a.height;
  }
  return s;
}

function aoiPercentage(num, per) {
  return num * ((100 - per) / 100);
}

class Teaser {
  constructor(options = {}) {
    this.lastItem = options.lastItem;
    this.breakpoints = {};
    this.id = options.id;
    this.css = options.css || { general: [] };
    this.premium = !!options.premium;
    this.important = !!options.isImportant;
    this.hasImage = options.hasImage;
    this.firstTeaser = !!options.firstTeaser;
    this.heightPercentage =
      options.imgAoi && options.imgAoi.heightPercentage
        ? options.imgAoi.heightPercentage
        : 0;
    this.widthPercentage =
      options.imgAoi && options.imgAoi.widthPercentage
        ? options.imgAoi.widthPercentage
        : 0;
    this.aoi = options.imgAoi;
    this.data = options.data || {};
    this.type = options.type;
    if (options.layout) {
      Object.keys(options.layout).forEach((key) => {
        const breakpoint = parseInt(key, 10);
        if (typeof breakpoint !== 'number' || Number.isNaN(breakpoint)) {
          return;
        }
        this.addBreakPoint(breakpoint, options.layout[key], key);
      });
    }
  }

  addBreakPoint(breakpoint, layout = [], key) {
    if (this.breakpoints[breakpoint]) {
      return this;
    }

    this.breakpoints[breakpoint] = { ...defaultBreakpoints };

    // Flatten layout object
    this.breakpoints[breakpoint].layout = layout.reduce((result, l) => {
      if (!l.versions) {
        result.push({ version: 'default', width: l.width, ...defaultVersion });
        return result;
      }
      return result.concat(
        Object.keys(l.versions).map((version) => {
          // Choose correct dynamic version
          const masterVersion = l.versions[version][0];

          const dynamicVersion = l.versions[version]
            .slice(1)
            .filter((ver) => {
              if (!ver || !ver.test) {
                return false;
              }
              let testFunc;
              try {
                testFunc = new Function('data', ver.test);
              } catch (e) {
                debug.log(e);
                return false;
              }
              return testFunc(this.data);
            })
            .sort((a, b) => a.height - b.height)
            .pop();

          if (dynamicVersion && dynamicVersion.height > masterVersion.height) {
            Object.keys(dynamicVersion).forEach((k) => {
              if (k === 'styles') {
                masterVersion.styles = masterVersion.styles.concat(
                  dynamicVersion.styles
                );
              } else {
                masterVersion[k] = dynamicVersion[k];
              }
            });
          }

          return {
            version,
            width: l.width,
            ...defaultVersion,
            ...masterVersion,
          };
        })
      );
    }, []);
    this.breakpoints[breakpoint].type = key;
    return this;
  }

  setActiveBreakpoint(viewportWidth) {
    // Test if we have a breakpoint below the viewport width
    let activeBreakpoint = Object.keys(this.breakpoints)
      .filter((key) => key <= viewportWidth)
      .sort()
      .pop();

    if (activeBreakpoint) {
      this.activeBreakpoint = this.breakpoints[activeBreakpoint];
      return this;
    }

    // If not, get the smalles breakpoint
    activeBreakpoint = Object.keys(this.breakpoints).sort().shift();

    this.activeBreakpoint = this.breakpoints[activeBreakpoint];
    return this;
  }

  setWidth(widths, maxMinWidth = 10000) {
    if (!this.activeBreakpoint) {
      return false;
    }

    this.activeBreakpoint.active = false;
    widths.every((availableWidth) => {
      let version;
      if (availableWidth === 'largest') {
        version = this.activeBreakpoint.layout
          .filter(
            (layout) =>
              !(
                this.activeBreakpoint.version &&
                layout.version !== this.activeBreakpoint.version
              ) && layout.width <= maxMinWidth
          )
          .sort(sortTeasers)
          .shift();

        if (version) {
          this.activeBreakpoint.active = version;
          return false;
        }
        return true;
      }
      version = this.activeBreakpoint.layout
        .filter(
          (layout) =>
            !(
              this.activeBreakpoint.version &&
              layout.version !== this.activeBreakpoint.version
            ) && layout.width === availableWidth
        )
        .sort((a, b) => b.height - a.height)
        .shift();

      if (version) {
        this.activeBreakpoint.active = version;
        return false;
      }
      return true;
    });
    return this.activeBreakpoint.active;
  }

  hasSize(size) {
    if (!this.activeBreakpoint) {
      return false;
    }

    const version = this.activeBreakpoint.layout
      .filter(
        (layout) =>
          !(
            this.activeBreakpoint.version &&
            layout.version !== this.activeBreakpoint.version
          ) && layout.width === size
      )
      .sort(sortTeasersAsc)
      .shift();

    return !!version;
  }

  getSmallestSize(size) {
    if (!this.activeBreakpoint) {
      return false;
    }

    const version = this.activeBreakpoint.layout
      .filter(
        (layout) =>
          !(
            this.activeBreakpoint.version &&
            layout.version !== this.activeBreakpoint.version
          ) && layout.width >= size
      )
      .sort(sortTeasersAsc)
      .shift();

    return version;
  }

  setWidthBestFit(availableWidth, availableHeight, fixedWidth) {
    if (!this.activeBreakpoint) {
      return false;
    }

    const version = this.activeBreakpoint.layout
      .filter(
        (layout) =>
          !(
            this.activeBreakpoint.version &&
            layout.version !== this.activeBreakpoint.version
          ) &&
          (!fixedWidth
            ? layout.width <= availableWidth
            : layout.width === availableWidth) &&
          layout.height - (layout.minHeight || 0) <= availableHeight
      )
      .sort((a, b) => {
        if (this.hasImage) {
          return sortTeasers(a, b);
        }
        return a.width - b.width;
      })
      .shift();

    this.activeBreakpoint.active = version;

    return this.activeBreakpoint.active;
  }

  decreaseSize(dryRun) {
    if (!this.activeBreakpoint) {
      return false;
    }

    const version = this.activeBreakpoint.layout
      .filter(
        (layout) =>
          !(
            this.activeBreakpoint.version &&
            layout.version !== this.activeBreakpoint.version
          ) && layout.width < this.activeBreakpoint.active.width
      )
      .sort(sortTeasers)
      .shift();

    if (!version) {
      return false;
    }

    if (dryRun) {
      return version;
    }

    this.activeBreakpoint.active = version;

    return this.activeBreakpoint.active;
  }

  increaseSize(dryRun) {
    if (!this.activeBreakpoint) {
      return false;
    }

    const version = this.activeBreakpoint.layout
      .filter(
        (layout) =>
          !(
            this.activeBreakpoint.version &&
            layout.version !== this.activeBreakpoint.version
          ) && layout.width > this.activeBreakpoint.active.width
      )
      .sort(sortTeasersAsc)
      .shift();

    if (!version) {
      return false;
    }

    if (dryRun) {
      return version;
    }

    this.activeBreakpoint.active = version;

    return this.activeBreakpoint.active;
  }

  setVersion(version, width) {
    if (!this.activeBreakpoint) {
      return false;
    }

    let validVersion;
    if (width) {
      validVersion =
        this.activeBreakpoint.layout.filter(
          (l) => l.width === width && l.version === version
        ).length !== 0;
    } else {
      validVersion = this.activeBreakpoint.layout.some(
        (l) => l.version === version
      );
    }

    if (validVersion) {
      this.activeBreakpoint.version = version;
    } else {
      this.activeBreakpoint.version = 'default';
    }
    return validVersion;
  }

  getStyles() {
    if (!this.activeBreakpoint || !this.activeBreakpoint.active) {
      return false;
    }

    const styles = { common: [], mediaqueries: [], animations: [] };
    this.css.general.forEach((css) => {
      css = css.trim();
      const mediaquery = css.match(mediaqueryPattern);
      if (mediaquery) {
        if (
          cssmq.match(mediaquery[1], {
            type: 'screen',
            width: this.activeBreakpoint.type,
          })
        ) {
          styles.mediaqueries.push(css);
        }
      } else {
        styles.common.push(css);
      }
    });

    if (this.css.animations) {
      styles.animations = styles.animations.concat(this.css.animations);
    }

    if (this.activeBreakpoint.active.styles) {
      this.activeBreakpoint.active.styles.forEach((css) => {
        css = css.trim();
        const mediaquery = css.match(mediaqueryPattern);
        if (mediaquery) {
          if (
            cssmq.match(mediaquery[1], {
              type: 'screen',
              width: this.activeBreakpoint.type,
            })
          ) {
            styles.mediaqueries.push(css);
          }
        } else {
          styles.common.push(css);
        }
      });
    }
    const version = this.activeBreakpoint.active.version || 'default';
    if (this.css[version]) {
      this.css[version].forEach((css) => {
        css = css.trim();
        const mediaquery = css.match(mediaqueryPattern);
        if (mediaquery) {
          if (
            cssmq.match(mediaquery[1], {
              type: 'screen',
              width: this.activeBreakpoint.type,
            })
          ) {
            styles.mediaqueries.push(css);
          }
        } else {
          styles.common.push(css);
        }
      });
    }
    return {
      common: styles.common.join('\n'),
      mediaqueries: styles.mediaqueries.join('\n'),
      animations: styles.animations.join('\n'),
    };
  }

  getInfo() {
    if (!this.activeBreakpoint || !this.activeBreakpoint.active) {
      return false;
    }
    return this.activeBreakpoint.active;
  }

  calculateReduction(minHeight, size, minImageHeight, imageHeight) {
    let reduction = Math.min(minHeight, size);

    if (minImageHeight) {
      const maxReduction =
        imageHeight - minImageHeight < 0 ? 0 : imageHeight - minImageHeight;
      reduction = reduction < maxReduction ? reduction : maxReduction;
    }

    return reduction;
  }

  shrink(size) {
    if (
      !size ||
      !this.aoi ||
      !this.activeBreakpoint ||
      !this.activeBreakpoint.active
    ) {
      return 0;
    }
    const { imageHeight, minImageHeight } = this.activeBreakpoint.active;

    if (imageHeight && this.activeBreakpoint.active.version === 'default') {
      let minHeight = this.activeBreakpoint.active.minHeight || 0;

      const reduction = this.calculateReduction(
        minHeight,
        size,
        minImageHeight,
        imageHeight
      );

      const scale = imageHeight / this.aoi.orgHeight;
      const aoiTop = this.aoi.y * scale;
      this.activeBreakpoint.active.imageHeight -= reduction || 0;
      this.activeBreakpoint.active.height -= reduction;
      this.activeBreakpoint.active.imageTop =
        (Math.min(aoiTop, reduction) /
          this.activeBreakpoint.active.imageHeight) *
        100;
      return reduction;
    }

    return 0;
  }

  getImageWidth(expansion, ratio) {
    const tempWidth =
      this.activeBreakpoint.active.imageWidth ||
      this.activeBreakpoint.active.width;
    const imageWidth = tempWidth + expansion * ratio;

    return {
      imageWidth,
      imageWidthPercentage:
        (imageWidth / this.activeBreakpoint.active.width) * 100,
    };
  }

  grow(size) {
    if (!size || !this.activeBreakpoint || !this.activeBreakpoint.active) {
      return 0;
    }
    const { imageHeight } = this.activeBreakpoint.active;
    if (
      imageHeight &&
      this.aoi &&
      this.activeBreakpoint.active.version === 'default'
    ) {
      const minHeight = this.activeBreakpoint.active.minHeight || 0;
      let expansion = Math.min(minHeight, size);
      const scale = imageHeight / this.aoi.orgHeight;
      const ratio = this.aoi.orgWidth / this.aoi.orgHeight;
      const aoiLeft = this.aoi.x * scale;

      if (expansion) {
        let imageWidthInfo = this.getImageWidth(expansion, ratio);

        const aoiVisible = Math.abs(this.aoi.widthPercentage - 100);
        const imageVisible = Math.abs(
          100 - imageWidthInfo.imageWidthPercentage
        );
        if (aoiVisible < imageVisible) {
          // If aoi is outside view, recalculate expansion
          expansion *= aoiVisible / 100;
          imageWidthInfo = this.getImageWidth(expansion, ratio);
        }
        this.activeBreakpoint.active.imageHeight += expansion;
        this.activeBreakpoint.active.height += expansion;

        const imageWidthDiff =
          imageWidthInfo.imageWidth - this.activeBreakpoint.active.width;
        if (imageWidthDiff > 0) {
          this.activeBreakpoint.active.imageWidthPercent =
            imageWidthInfo.imageWidthPercentage;
          const imageLeft = Math.min(aoiLeft, imageWidthDiff);
          this.activeBreakpoint.active.imageLeft =
            (imageLeft / this.activeBreakpoint.active.width) * 100;
        }

        return expansion;
      }
    } else if (this.hasImage === false) {
      const expansion = Math.min(
        size,
        this.activeBreakpoint.active.height /
          this.activeBreakpoint.active.lineCount
      );
      this.activeBreakpoint.active.verticalPadding = expansion;
      this.activeBreakpoint.active.height += expansion;
      return expansion;
    }

    return 0;
  }

  setMinImageHeight() {
    if (this.aoi && this.activeBreakpoint && this.activeBreakpoint.active) {
      const { imageHeight } = this.activeBreakpoint.active;

      const imageHeightPercentage =
        (imageHeight / this.activeBreakpoint.active.height) * 100;

      const scale = imageHeight / this.aoi.orgHeight;

      const aoiTop = this.aoi.y * scale;
      if (imageHeight && this.activeBreakpoint.active.version === 'default') {
        // Todo move this to layout-definitions config
        const heightPercentage =
          parseInt(this.activeBreakpoint.type, 10) === 500
            ? Math.max(75, this.aoi.heightPercentage)
            : this.aoi.heightPercentage;
        const reduction =
          imageHeightPercentage < heightPercentage
            ? 0
            : aoiPercentage(imageHeight, heightPercentage);
        this.activeBreakpoint.active.imageHeight -= reduction;
        this.activeBreakpoint.active.height -= reduction;
        this.activeBreakpoint.active.imageTop =
          (Math.min(aoiTop, reduction) /
            this.activeBreakpoint.active.imageHeight) *
          100;
      }
    }
  }
  /*
    Temporary function for setting a lower imageheight if the teaser has a maxImageHeight attribute.
  */
  setMaxImageHeight() {
    if (this.aoi && this.activeBreakpoint && this.activeBreakpoint.active) {
      const { imageHeight, maxImageHeight } = this.activeBreakpoint.active;
      if (imageHeight && maxImageHeight) {
        const imageReduction = imageHeight - maxImageHeight;
        const hasAOI = this.aoi.y !== 0 || this.aoi.x !== 0;
        const scale = imageHeight / this.aoi.orgHeight;
        const aoiTop = this.aoi.y * scale;
        const reduceImage =
          hasAOI && imageReduction > 0 && imageReduction < aoiTop;

        if (reduceImage) {
          const imageTop = hasAOI
            ? (imageReduction / this.activeBreakpoint.active.imageHeight) * 100
            : 0;
          this.activeBreakpoint.active.imageHeight -= imageReduction;
          this.activeBreakpoint.active.height -= imageReduction;
          this.activeBreakpoint.active.imageTop = imageTop;
        }
      }
    }
  }
}

export { Teaser };
