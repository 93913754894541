'use strict';

const smallScreen = 500;
const smallScreenTeaserSizes = [500];
const largeScreen = 980;
const largeScreenTeaserSizes = [980, 880, 780, 680, 580, 480, 380, 280];

const layouts = {
  [smallScreen]: {
    width: smallScreen,
    getInitialTeaserSize: (teaser, compositions) => {
      if (teaser.firstTeaser || teaser.important) {
        teaser.setVersion('default');
      }
      if (compositions && compositions.length > 0) {
        const compositionsString = compositions[compositions.length - 1].join();
        if (compositionsString === '242-default,242-default') {
          teaser.setVersion('imageLeft');
        }
      }
      return [smallScreen, 'largest'];
    },
    indexDelta: (teaserList) => {
      let delta = 0;

      if (teaserList.length === 0) {
        return 0;
      }

      if (Array.isArray(teaserList[0])) {
        delta = 1;
      }

      return teaserList[delta] === undefined || teaserList[delta].hasImage
        ? delta
        : delta + 1;
    },
    getAvailableSizes: () => [...smallScreenTeaserSizes],
    chooseVersion: (teaser, compositions, fullwidth, index, groupType) => {
      if (groupType === 'complex') {
        return teaser.important
          ? teaser.setVersion('default')
          : teaser.setVersion('imageLeft', smallScreen);
      }
      if (compositions && compositions.length > 0) {
        const compositionsString = compositions[compositions.length - 1].join();
        if (compositionsString === `${smallScreen}-imageLeft`) {
          return teaser.setVersion('default');
        }
      }
      if (fullwidth && index !== 0) {
        return teaser.setVersion('imageLeft', smallScreen);
      }
      if (index === 0 || teaser.important) {
        return teaser.setVersion('default');
      }
      return teaser.setVersion('imageLeft', smallScreen);
    },
    hPadding: 16,
    vPadding: 24,
    column: 500,
    alterDirection: false,
    teaserSizes: [...smallScreenTeaserSizes],
    columns: 1,
    breakOnDiff: 60,
  },
  [largeScreen]: {
    width: largeScreen,
    getInitialTeaserSize: (teaser) => {
      let size = largeScreenTeaserSizes[largeScreenTeaserSizes.length - 1];

      if (!teaser.hasImage) {
        size = largeScreenTeaserSizes[5];
      }

      if (teaser.premium || teaser.important || teaser.firstTeaser) {
        size = largeScreenTeaserSizes[3];
      }

      if (!teaser.hasImage && teaser.firstTeaser) {
        size = largeScreenTeaserSizes[0];
      }
      return [size, 'largest'];
    },
    indexDelta: () => 0,
    getAvailableSizes: (index) => {
      if (index < 2) {
        return [...largeScreenTeaserSizes].filter((_, i) => i !== 5); // We cannot have an equal 50/50 split on the top of the page
      }
      return [...largeScreenTeaserSizes];
    },
    chooseVersion: (teaser, compositions, fullwidth, index) => {
      if (fullwidth && index !== 0) {
        return teaser.setVersion('imageLeft', largeScreen);
      }

      if (!fullwidth) {
        return teaser.setVersion('default');
      }
      return teaser.setVersion('imageLeft', largeScreen);
    },

    hPadding: 20,
    vPadding: 30,
    column: 80,
    alterDirection: true,
    teaserSizes: [...largeScreenTeaserSizes],
    columns: 10,
    breakOnDiff: 0,
  },
};

function getLayoutKeys() {
  return Object.keys(layouts).map((key) => parseInt(key, 10));
}

function getLayout() {
  const viewPortWidth = window.innerWidth;
  const sortedLayoutWidths = getLayoutKeys().sort((a, b) => a - b);
  return (
    sortedLayoutWidths.find((layoutWidth) => viewPortWidth <= layoutWidth) ||
    Math.max(...sortedLayoutWidths)
  );
}

export { layouts, getLayout, getLayoutKeys };
