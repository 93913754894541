// @ts-nocheck
/* eslint-env browser */

'use strict';

// @ts-ignore

import './amedia-img.js';

import { getBrowserId } from '@amedia/browserid';
import { UserDataRequest } from '@amedia/user';

import PageLayout from './page-layout.js';
import GroupPageLayout from './group-page-layout.js';
import stitcher from './stitcher.js';
import {
  getRecommendations,
  getRecommendationsBrowserId,
} from './getUserRecommendations.js';
import { mixinFrontPageElements, getTeaserIdMap } from './mixins.js';
import { restoreScrollPosition } from './restore-scroll-position.js';
import { PersonalisedDataExperiment } from './experiment-personalised.js';
import { fetchData } from './backend.js';
import { token } from './ab-token.js';
import { isArticle, applyArticleTreatment } from './article-bottom.js';
import { getLayout, getLayoutKeys, layouts } from './layout-definitions.js';
import renderSmartEmbed from './smart-embed.js';
import { renderGhostTeaser } from './ghost-teaser.js';

import './optimus-element.js';

const currentLayout = getLayout();
const breakpoints = getLayoutKeys()
  .sort()
  .map((key) => layouts[key]);

class Frontpage extends HTMLElement {
  connectedCallback() {
    this.pages = [];
    this.publication = this.getAttribute('publication');
    this.theme = this.getAttribute('theme');
    this.brickTeaserVersions = new Map();
    this.init();
  }

  getFrontpageObjects() {
    try {
      return JSON.parse(window.atob(this.getAttribute('common-elements')));
    } catch (e) {
      return [];
    }
  }

  setId() {
    const id = this.getAttribute('id');
    if (!id) {
      this.setAttribute(
        'id',
        `front-${this.getElementsByTagName('amedia-frontpage').length}`
      );
    }
  }

  get src() {
    const src = this.getAttribute('src');
    if (window.location.protocol === 'https:') {
      return src.replace('http:', 'https:');
    }
    return src;
  }

  setBrickTeaserVersions(teaser) {
    if (teaser.css) {
      const brickTeaserVersion = teaser.css.brickTeaserVersion;

      if (brickTeaserVersion) {
        this.brickTeaserVersions.set(`${brickTeaserVersion}`, {
          brickTeaserVersion,
        });
      }
    }
  }

  async init() {
    try {
      this.setId();
      const nliHash = this.hasAttribute('inject-nli')
        ? this.getAttribute('inject-nli')
        : 'not-in-use';

      const [browserid] = await Promise.all([
        getBrowserId().catch(() => undefined),
      ]);

      await new UserDataRequest()
        .withAttributes([
          'name',
          'privacyPreferences',
          'extraData',
          'trackingKey',
          'uuid',
        ])
        .fetch({ timeout: 1000 })
        .then(({ attributes, state }) => {
          this.userAttributes = attributes;
          this.userState = state;
        })
        .catch(() => {
          this.userState = { isLoggedIn: false };
        });
      const authenticated = this.userState.isLoggedIn;

      let recommendations = authenticated
        ? getRecommendations(this.userAttributes, nliHash)
        : getRecommendationsBrowserId(browserid, nliHash);

      if (
        authenticated &&
        !this.userAttributes?.privacyPreferences?.personalizedContent
      ) {
        recommendations = {};
      }
      const primary = await fetchData(this.src, token, recommendations);
      const pde = new PersonalisedDataExperiment(primary);
      this.appendChild(pde.getPageTracerMarkup());
      const filtered = pde.teaserData;
      const teaserIdMap = getTeaserIdMap(filtered);
      const { queryParams } = primary;

      let isAmediaStaff;
      let hasVideoAccess = false;
      if (this.userState.isLoggedIn) {
        isAmediaStaff = Boolean(this.userAttributes.extraData.amedia_staff);
        hasVideoAccess = Boolean(
          this.userAttributes.extraData.customer_keys ||
            this.userAttributes.extraData.plussalt_subscription
        );
      }
      const showPlayerToUser = hasVideoAccess || isAmediaStaff;

      let teaserItems = await Promise.all(
        filtered.map(async (teaser) => {
          teaser = renderSmartEmbed(teaser, this.userAttributes);

          if (teaser.type === 'ghost-teaser') {
            teaser = renderGhostTeaser({
              teaser,
              theme: this.theme,
              publication: this.publication,
              showPlayerToUser,
            });
          }

          if (teaser.unknownHeight) {
            const group = [{ type: 'meta', unknownHeight: true }, teaser];
            group.unknownHeight = true;
            return group;
          }

          // is it a group?
          if (Array.isArray(teaser)) {
            teaser = teaser.filter(
              (element) => element && !teaserIdMap.get(element.id)
            );
            teaser = teaser.map(renderSmartEmbed);
            return Promise.all(
              teaser.map(async (element) => {
                if (element?.type === 'ghost-teaser') {
                  element = renderGhostTeaser({
                    teaser: element,
                    theme: this.theme,
                    publication: this.publication,
                    showPlayerToUser,
                  });

                  return element;
                }

                const playVideo = showPlayerToUser || !element.premium;
                // element in group is prerendered
                if (
                  element.videoTeaserMarkup &&
                  element.playOnFront &&
                  playVideo
                ) {
                  element.html = element.videoTeaserMarkup.html;
                }
                this.setBrickTeaserVersions(element);
                element.hasVideoAccess = playVideo;
                element.teaserType =
                  element?.videoTeaserMarkup?.type ||
                  element?.contentType ||
                  '';

                return element;
              })
            );
          }
          // element is prerendred

          const playVideo = showPlayerToUser || !teaser.premium;

          if (teaser.videoTeaserMarkup && teaser.playOnFront && playVideo) {
            teaser.html = teaser.videoTeaserMarkup.html;
          }

          this.setBrickTeaserVersions(teaser);
          teaser.hasVideoAccess = playVideo;
          teaser.teaserType =
            teaser?.videoTeaserMarkup?.type || teaser?.contentType || '';

          return teaser;
        })
      );

      teaserItems.filter((item) => !item.error);

      if (isArticle() && this.userState.isLoggedIn) {
        teaserItems = applyArticleTreatment(teaserItems, this.userAttributes);
      }

      const posDelta = layouts[currentLayout].indexDelta(teaserItems);
      const frontPageElements = this.getFrontpageObjects();
      teaserItems = mixinFrontPageElements(
        frontPageElements,
        posDelta,
        teaserItems
      );

      // add firstTeaser = true to first teasers until we reach a teaser with an image
      // this is done for layout purposes. We want the first teaser with an image to be large.
      teaserItems.every((teaser) => {
        if (teaser.type === 'teaser') {
          teaser.firstTeaser = true;
          return !teaser.hasImage;
        }
        return true;
      });

      // Split up fronts and add them
      const batchOfElements = stitcher(teaserItems);
      let list;

      while ((list = batchOfElements())) {
        this.addContent(list);
      }
      this.renderPages();

      const spinner = this.querySelector('amedia-spinner');
      spinner?.parentNode.removeChild(spinner);
      this.querySelector('#ordinoTracer').appendChild(this.content);

      if (!isArticle()) {
        restoreScrollPosition();
      }

      window.dispatchEvent(
        new CustomEvent('frontpage-rendered', { detail: { queryParams } })
      );
    } catch (error) {
      this.remove();
      error.message = `Optimus fallback: ${error.message || ''}`;
      console.error(error);
    }
  }

  addBrickStyling() {
    let styleLinkHtml = '';
    this.brickTeaserVersions.forEach((value) => {
      const { brickTeaserVersion } = value;
      styleLinkHtml += `<link media="all" rel="stylesheet" type="text/css" href="https://assets.acdn.no/pkg/@amedia/brick-teaser/${brickTeaserVersion}/css/brick-teaser.css">`;
    });

    return styleLinkHtml;
  }

  iOShidePopcornOverlay() {
    window.addEventListener('popstate', () => {
      if (location.pathname === '/') {
        const popcornOverlay = this.querySelector('#popcorn-overlay');
        popcornOverlay?.removeAttribute('class', 'active');

        const body = document.querySelector('body');
        body?.style.removeProperty('overflow');
      }
    });
  }

  iOSaddPopcornOverlay() {
    this.iOShidePopcornOverlay();

    const iOS = /iP(ad|hone|od)/.test(navigator.userAgent);
    const ua = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const isApp = /pushvarslings-app/.test(navigator.userAgent);

    if (iOS && ua && parseInt(ua[1], 10) >= 16 && !isApp) {
      return `
          <div id="popcorn-overlay-parent">
            <amedia-include
              id="popcorn-overlay"
              lazy="true"
              manifest="https://services.api.no/api/popcorn/v1/manifest"
              param-article-id=""
              param-publication="${this.publication}"
              param-overlay="true"
            />
          </div>
        `;
    }
    return '';
  }

  renderPages() {
    let html = '';

    html += this.addBrickStyling();

    this.pages.forEach((page) => {
      if (!page) {
        return;
      }

      const pageInfo = page.close();
      html += pageInfo.html;

      if (pageInfo.jsExternal) {
        pageInfo.jsExternal.forEach((asset) => {
          html += `<script type="module" src="${asset}"></script>`;
        });
      }
    });

    html += this.iOSaddPopcornOverlay();

    const range = document.createRange();
    // Safari in iOS9 needs the context for the range to
    // be set explicitly, this is done with selectNode(this)
    // which sets the range to contain this custom element and its content
    range.selectNode(this);
    this.content = range.createContextualFragment(html);
  }

  addContent(list) {
    let [page] = this.pages.slice(-1);
    const id = `front${this.pages.length}`;
    this.contentIndex = this.contentIndex || 0;
    const { meta } = list;

    const containerIndex = page
      ? page.containerIndex
      : getLayoutKeys().reduce(
          (result, width) => ({ ...result, [width]: 0 }),
          {}
        );

    const options = {
      ...meta,
      index: meta && meta.type === 'complex' ? 0 : this.contentIndex,
      containerIndex,
      group: meta ? meta.type === 'group' || meta.type === 'complex' : false,
    };

    this.contentIndex += list.length;
    if (!options.group) {
      // Fill previous container
      const lastAvailablePage = this.pages
        .slice()
        .reverse()
        .find((p) => !p.group);
      if (lastAvailablePage) {
        list = lastAvailablePage.add(list, currentLayout);
      }
    } else if (list[0]) {
      list[0].firstTeaser = true;
    }

    if (list.length !== 0) {
      if (
        list.meta?.groupType === 'list-horizontal' ||
        list.meta?.groupType === 'default'
      ) {
        page = new GroupPageLayout({
          id,
          breakpoints,
          options,
          theme: this.theme,
          meta: list.meta,
        });
        page.add(list);
      } else {
        page = new PageLayout({
          id,
          breakpoints,
          options,
          theme: this.theme,
        });
        page.add(list);
      }
      this.pages.push(page);
    }
  }
}

if (customElements && !customElements.get('amedia-frontpage')) {
  customElements.define('amedia-frontpage', Frontpage);
} else if (!customElements) {
  console.error('This browser does not support Custom Elements');
}
