import testForLocalStorage from './test-for-localstorage.js';

let tok = 10000;
if (testForLocalStorage()) {
  tok = window.localStorage.getItem('ab_token');
  if (!tok) {
    tok = Math.floor(Math.random() * 899999) + 100000;
    window.localStorage.setItem('ab_token', tok);
  }
}

export const token = tok;
