'use strict';

function sortTeaserColumns(teaserColumns, vPadding) {
  return teaserColumns
    .map((teaserColumn, index) => {
      const height =
        teaserColumn.reduce((pv, teaser) => pv + teaser.height, 0) +
        (teaserColumn.length - 1) * vPadding;
      return {
        index,
        height,
        length: teaserColumns.length,
      };
    })
    .sort((a, b) => a.height - b.height)
    .filter((teaser, index, arr) => index === 0 || index === arr.length - 1);
}

function getHeightDiffFunction(columnsSorted, vPadding, percentage) {
  if (!percentage) {
    return columnsSorted[1].height - columnsSorted[0].height;
  }
  return (columnsSorted[0].height / columnsSorted[1].height) * 100;
}

function setHeight(teaserColumns, vPadding, sizes, firstContainer) {
  const columnsSorted = sortTeaserColumns(teaserColumns, vPadding);
  // More restrictive for first container. Only change layout if we have very much whitespace
  if (
    firstContainer &&
    getHeightDiffFunction(columnsSorted, vPadding, true) > 60
  ) {
    return;
  }
  const diff = getHeightDiffFunction(columnsSorted, vPadding);
  const tallestColumn = teaserColumns[columnsSorted[1].index];
  const smallestColumn = teaserColumns[columnsSorted[0].index];
  let fails = false;

  const newSmallestHeight = smallestColumn.reduce((pv, teaserWrapper, i) => {
    const info = teaserWrapper.teaser.increaseSize(true);
    if (!info) {
      fails = true;
      return pv;
    }
    if (sizes.indexOf(info.width) === -1) {
      fails = true;
      return pv;
    }
    return pv + info.height + i * vPadding;
  }, 0);

  const newTallestHeight = tallestColumn.reduce((pv, teaserWrapper, i) => {
    const info = teaserWrapper.teaser.decreaseSize(true);
    if (!info) {
      fails = true;
      return pv;
    }
    if (sizes.indexOf(info.width) === -1) {
      fails = true;
      return pv;
    }
    return pv + info.height + i * vPadding;
  }, 0);

  if (fails) {
    return;
  }

  const newDiff = Math.abs(newTallestHeight - newSmallestHeight);
  const alterColumnWidths = newDiff < diff;

  if (alterColumnWidths) {
    smallestColumn.forEach((teaserWrapper) => {
      const info = teaserWrapper.teaser.increaseSize();
      teaserWrapper.width = info.width;
      teaserWrapper.height = info.height;
    });

    tallestColumn.forEach((teaserWrapper) => {
      const info = teaserWrapper.teaser.decreaseSize();
      teaserWrapper.width = info.width;
      teaserWrapper.height = info.height;
    });
    setHeight(teaserColumns, vPadding, sizes);
  }
}

function shrink(iterations, teaserColumns, vPadding) {
  iterations -= 1;
  const columnsSorted = sortTeaserColumns(teaserColumns, vPadding);
  let diff = getHeightDiffFunction(columnsSorted, vPadding);
  let reduction = diff / teaserColumns[columnsSorted[1].index].length;

  teaserColumns[columnsSorted[1].index]
    .slice(0)
    .sort((a, b) => b.teaser.getInfo().minHeight - a.teaser.getInfo().minHeight)
    .forEach((teaserWrapper) => {
      const factor = teaserWrapper.teaser.shrink(reduction);
      diff -= factor;
      reduction += reduction - factor;
      teaserWrapper.height = teaserWrapper.teaser.getInfo().height;
    });

  reduction = diff / teaserColumns[columnsSorted[0].index].length;
  teaserColumns[columnsSorted[0].index]
    .slice(0)
    .sort((a, b) => {
      const maxHeight = a.teaser.getInfo().maxHeight;
      if (!maxHeight) {
        return 1;
      }
      return b.teaser.getInfo().maxHeight - maxHeight;
    })
    .forEach((teaserWrapper) => {
      const factor = teaserWrapper.teaser.grow(reduction);
      reduction += reduction - factor;
      teaserWrapper.height = teaserWrapper.teaser.getInfo().height;
    });

  if (iterations > 0) {
    shrink(iterations, teaserColumns);
  }
}

function setCoordinates(teaserColumns, ltr = true, vPadding, hPadding) {
  let left = 0;
  let top = 0;
  // Change direction of row layout.
  // Check first and last column to find the current visual direction (from large to small)
  if (teaserColumns.length > 1 && teaserColumns[0][0]) {
    const visualLeft =
      teaserColumns[0][0].width >
      teaserColumns[teaserColumns.length - 1][0].width;
    ltr = (ltr && visualLeft) || (!ltr && !visualLeft);

    if (!ltr) {
      teaserColumns = teaserColumns.reverse();
    }
  }
  teaserColumns.forEach((column) => {
    top = 0;
    column.forEach((teaserWrapper) => {
      teaserWrapper.top = top;
      const info = teaserWrapper.teaser.getInfo();
      top += info.height + vPadding;
      teaserWrapper.left = left;
    });
    left += column[0].teaser.getInfo().width + hPadding;
  });
}

export function getHeightDiff(teaserColumns, vPadding, percentage) {
  const columnsSorted = sortTeaserColumns(teaserColumns, vPadding);
  return getHeightDiffFunction(columnsSorted, vPadding, percentage);
}
export { setHeight, shrink, setCoordinates };
