import { uuid } from '@amedia/uuid';

import { getVersions } from './versions.js';

// A random staticLayout for now, the heights/ratios should be replaced with something cool and dynamic.
// min/max-width should come from the source?

const staticLayout = {
  dimensions: {
    280: {
      ratio: 120,
    },
    380: {
      ratio: 110,
    },
    480: {
      ratio: 100,
    },
    500: {
      ratio: 80,
    },
  },
  width: {
    min: 280,
    max: 500,
  },
};

export function renderGhostTeaser({
  teaser,
  theme,
  publication,
  showPlayerToUser = false,
}) {
  let matchingPreference = '';

  let isSportsTeaser = false;
  if (teaser.id.startsWith('19')) {
    isSportsTeaser = true;
    matchingPreference = `${teaser.sourceParticipant}`;
  }

  //const teaserId = '5-55-1272670';
  //const frontPageId = '11-58ca5d2453215e0ad6b3f241';

  const teaserId = teaser.id;
  const contentType = teaser.contentType;
  let frontPageId = teaser.frontpage_id;
  if (!frontPageId) {
    frontPageId = teaser.frontpageID;
  }
  const checksum = teaser.checksum;
  let purpose = teaser.auxData?.domain;
  if (!purpose) {
    purpose = teaser.domain;
  }

  const teaserData = `<amedia-laserbeak-v0 sports="${isSportsTeaser}" frontpage-id="${frontPageId}" teaser-id="${teaserId}" checksum="${checksum}" purpose="${purpose}" theme="${theme}" publication="${publication}" preference="${matchingPreference}" has-access="${showPlayerToUser}" content-type="${contentType}"></amedia-laserbeak-v0>`;

  const renderedTeaser = {
    id: uuid(),
    teaserId: teaser.id,
    type: 'ghost-teaser',
    premium: teaser.premium,
    html: teaserData,
    js: [],
  };

  const layout = getVersions(staticLayout);
  if (layout) {
    renderedTeaser.layout = layout;
  } else {
    renderedTeaser.unknownHeight = true;
  }

  return renderedTeaser;
}
