/* eslint-env browser */

'use strict';

// Returning true if item has array of numbers where to add the comonent
function allPositions(item) {
  return item && item.insertPositions;
}

export function getTeaserIdMap(teasers) {
  const keyValuePairs = teasers
    .filter((teaser) => teaser.id && teaser.type === 'teaser')
    .map((teaser) => [teaser.id, true]);
  return new Map(keyValuePairs);
}

export function mixinFrontPageElements(frontPageElements, delta, primary = []) {
  // We get the positions from each component element and make a complete list
  // of components/ads that we add one ore multiple times to teaserItems,
  // at the correct index position listed in the item.insertPositions array
  frontPageElements
    .filter((item) => item.error === undefined)
    .map((item) => {
      if (Array.isArray(item)) {
        const itemPos = item.find(allPositions);
        return itemPos.insertPositions.map((pos) => {
          const componentElement = item.slice(0);
          componentElement.pos = pos + delta;
          return componentElement;
        });
      }

      return item.insertPositions.map((pos) => {
        const componentElement = { ...item }; // clone the element
        componentElement.pos = pos + delta;
        return componentElement;
      });
    })
    .reduce((a, b) => a.concat(b), []) // flatten
    .sort((a, b) => a.pos - b.pos)
    .forEach((el, i) => {
      const pos = el.pos + i;
      if (pos + 4 < primary.length) {
        return primary.splice(pos, 0, el);
      }
      return primary;
    });
  return primary;
}
