'use strict';

//import 'https://assets.acdn.no/pkg/@amedia/brick-carousel/v0/brick-carousel.js';

import { Container } from './container.js';
import { Teaser } from './teaser.js';
import { drEditionColorMapping } from './color-mapping.js';
import { getLayout } from './layout-definitions.js';
import { createSonar } from './create-sonar.js';

const currentLayout = getLayout();

let optimusElementIndex = 0;

function createContainer(breakpoint, ltr) {
  breakpoint.container = new Container({
    type: breakpoint.width,
    ltr,
    index: breakpoint.container.index + 1,
    vPadding: breakpoint.styles.vPadding,
    hPadding: breakpoint.styles.hPadding,
    compositions: breakpoint.styles.compositions,
  });
}

function createPageHeader(title, sonar, theme) {
  let html = '<header><h2>';
  if (sonar) {
    html += createSonar(theme);
  }
  html += `${title}</h2></header>`;
  return html;
}

function createLargePageHeader(title, sonar, largeTitleCss, theme) {
  let html = `<header><h2 class="${largeTitleCss}">`;
  if (sonar) {
    html += createSonar(theme);
  }
  html += `${title}</h2></header>`;
  return html;
}

class GroupPageLayout {
  constructor({ id, breakpoints, options, theme, meta }) {
    this.html = '';
    this.carouselMarkup = '';
    this.carouselChildrenCount = 0;
    this.groupFooterMarkupOne = '';
    this.groupFooterMarkupTwo = '';
    this.promoteFirstMarkup = '';
    this.defaultMarkup = '';
    this.meta = meta;
    this.jsExternal = [];
    this.id = id;
    this.breakpoints = [];
    this.pageHeight = {};
    this.theme = theme;
    Object.keys(options).forEach((key) => {
      this[key] = options[key];
    });

    let currentMinBreakpointWidth = 0;
    this.breakpoints = breakpoints
      .sort((a, b) => a.width - b.width)
      .map((breakpoint, i) => {
        const maxWidth = breakpoint.width + breakpoint.hPadding * 2;
        const lastBreakpoint = i === breakpoints.length - 1;
        const minWidth = currentMinBreakpointWidth;
        currentMinBreakpointWidth = maxWidth + 1;
        return {
          container: new Container({
            type: breakpoint.width,
            ltr: true,
            index: this.containerIndex[breakpoint.width] || 0,
            vPadding: breakpoint.vPadding,
            hPadding: breakpoint.hPadding,
            compositions: [],
          }),
          width: breakpoint.width,
          styles: {
            minWidth,
            maxWidth,
            ltr: true,
            vPadding: breakpoint.vPadding,
            hPadding: breakpoint.hPadding,
            lastBreakpoint,
            alterDirection: breakpoint.alterDirection,
            currentPageYPosition: 0,
            compositions: [],
          },
          index: options.index || 0,
        };
      });
  }

  getWrapMarkup = ({
    carouselChildrenCount,
    minSlidesToShow,
    carouselMarkup,
  }) => {
    if (carouselChildrenCount <= 0) {
      return '';
    }
    if (carouselChildrenCount === 1) {
      return carouselMarkup;
    } else {
      return `<brick-carousel min-slides-to-show="${minSlidesToShow}" style="--version:left">${carouselMarkup}</brick-carousel>`;
    }
  };

  close() {
    this.breakpoints = this.breakpoints
      .map((breakpoint) => {
        return breakpoint;
      })
      .sort((a, b) => b.width - a.width);

    let frontClass = 'front';
    let idClass = this.id;
    let minSlidesToShow = 2;
    if (this.groupType === 'soccer') {
      frontClass = 'soccer';
      idClass = 'soccer';
      minSlidesToShow = 1;
    }
    const wrapHorizontalGroup = this.getWrapMarkup({
      carouselChildrenCount: this.carouselChildrenCount,
      minSlidesToShow,
      carouselMarkup: this.carouselMarkup,
    });

    let html = `<div id="${idClass}" class=${frontClass}>
    ${this.html}</div>`;

    if (this.group && this.type === 'complex') {
      let title = '';
      let classes = `optimus-complex-front ${this.groupType}`;

      if (this.title && this.largeTitle === true) {
        const largeTitleCss = 'large';
        title = createLargePageHeader(
          this.title,
          this.breakingNews,
          largeTitleCss,
          this.theme
        );
      } else if (this.title && this.largeTitle === false) {
        title = createPageHeader(this.title, this.breakingNews, this.theme);
      } else {
        classes += ' noHeader';
      }
      if (this.backgroundColor) {
        classes += ` optimus-${drEditionColorMapping[this.backgroundColor]}`;
      }

      const videoRatio = this.videoRatio
        ? `style="--brick-player-ar-container:${this.videoRatio.replace(
            ':',
            '/'
          )};"`
        : '';

      const useHorizonalSpace =
        title ||
        wrapHorizontalGroup ||
        this.groupFooterMarkupOne ||
        this.groupFooterMarkupTwo;

      if (this.meta.groupType === 'list-horizontal') {
        const padding = useHorizonalSpace ? 'wrap-horizontal-space' : '';
        const isSolo = this.carouselChildrenCount === 1 ? 'solo-group' : '';

        html = `<section ${videoRatio} class="${classes}" data-group="ghost">${title}<div class="wrap-horizontal ${padding} ${isSolo}">${this.promoteFirstMarkup}${wrapHorizontalGroup}${this.groupFooterMarkupOne}${this.groupFooterMarkupTwo}</section>`;
      } else if (this.meta.groupType === 'default') {
        html = `<section class="${classes}" data-group="ghost">${title}<div class="wrap-default">${this.defaultMarkup}</div>${this.groupFooterMarkupOne}${this.groupFooterMarkupTwo}</section>`;
      }
    }

    html = `${html}`;
    return {
      html,
      jsExternal: this.jsExternal,
    };
  }

  handlePromoteFirstTeaser(teaserList) {
    const ghostTeasers = teaserList.filter(
      (teaser) => teaser.type === 'ghost-teaser'
    );

    const firstGhostTeaser = ghostTeasers.shift();
    if (firstGhostTeaser) {
      this.promoteFirstMarkup = `<div class="promote-first">${firstGhostTeaser.html}</div>`;

      const indexOf = teaserList.findIndex(
        (teaser) => teaser?.teaserId === firstGhostTeaser?.teaserId
      );
      teaserList.splice(indexOf, 1);
    }
  }

  handleGroupFooterOneItem(teaserList) {
    const lastItem = teaserList[teaserList.length - 1];
    this.groupFooterMarkupOne = lastItem.html;
    teaserList.pop();
  }

  handleGroupFooterTwoItems(teaserList) {
    const lastTwoItems = teaserList.slice(-2);
    this.groupFooterMarkupTwo = lastTwoItems[0].html += lastTwoItems[1].html;
    teaserList.splice(-2);
  }

  add(teaserList, onlyFillContainer) {
    let done = false;

    if (this.meta.groupType === 'list-horizontal' && this.meta.promoteFirst) {
      this.handlePromoteFirstTeaser(teaserList);
    }

    if (this.meta.groupFooter === 'lastItem') {
      this.handleGroupFooterOneItem(teaserList);
    } else if (this.meta.groupFooter === 'lastTwoItems') {
      this.handleGroupFooterTwoItems(teaserList);
    }

    return teaserList.filter((teaserData) => {
      if (done) {
        return true;
      }
      if (!teaserData) {
        return false;
      }

      const teaser = new Teaser(teaserData);

      let breakpoints = this.breakpoints.slice();

      if (onlyFillContainer) {
        breakpoints = breakpoints.sort((a, b) => {
          if (a.width === onlyFillContainer) {
            return -1;
          }
          if (b.width === onlyFillContainer) {
            return 1;
          }
          return 0;
        });
      }

      breakpoints.forEach((breakpoint) => {
        if (!done) {
          const isAdded = breakpoint.container.add(teaser, this.type);

          if (!isAdded && breakpoint.width === onlyFillContainer) {
            done = true;
          } else if (!isAdded) {
            this.containerIndex[breakpoint.width] = breakpoint.container.index;

            if (
              breakpoint.styles.alterDirection &&
              breakpoint.container.content.length > 1
            ) {
              breakpoint.styles.ltr = !breakpoint.styles.ltr;
            }
            createContainer(breakpoint, breakpoint.styles.ltr);
            breakpoint.container.add(teaser, this.type);
          }
        }
      });
      if (!done) {
        let attr = '';
        if (
          teaser.breakpoints &&
          teaser.breakpoints[currentLayout] &&
          teaser.breakpoints[currentLayout].active
        ) {
          attr += `linecount="${teaser.breakpoints[currentLayout].active.lineCount}" `;
          attr += `version="${teaser.breakpoints[currentLayout].active.version}" `;
          attr += `width="${teaser.breakpoints[currentLayout].active.width}" `;
          attr += `index="${optimusElementIndex}"`;
        }

        optimusElementIndex += 1;

        const isSoloHorizonal =
          teaserList.length === 1 && this.meta.groupType === 'list-horizontal';
        const isMultipleHorizontal =
          teaserList.length > 1 && this.meta.groupType === 'list-horizontal';

        if (isSoloHorizonal) {
          if (teaser.type === 'ghost-teaser') {
            this.carouselMarkup += teaserData.html;
            this.carouselChildrenCount += 1;
          }
        }

        if (isMultipleHorizontal) {
          if (teaser.type === 'ghost-teaser') {
            this.carouselMarkup += `<div class="carouselcontent ${this.carouselChildrenCount}">${teaserData.html}</div>`;
            this.carouselChildrenCount += 1;
          }
        }

        if (this.meta.groupType === 'default') {
          // only for default groups
          this.defaultMarkup += `<div class="group-default-element" id="art-${teaserData.id}" ${attr}>${teaserData.html}</div>`;
        }

        //this.html += `<optimus-element id="art-${teaserData.id}" ${attr}>${teaserData.html}</optimus-element>`;
      }
      return done;
    });
  }
}

export default GroupPageLayout;
