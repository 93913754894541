import { uuid } from '@amedia/uuid';

import { getVersions } from './versions.js';

const getMnemonicHost = () => {
  const { host } = window.location;
  if (host.includes('snap0.api.no')) {
    return 'services-snap0.api.no';
  } else if (host.includes('snap1.api.no')) {
    return 'services-snap1.api.no';
  } else if (host.includes('snap2.api.no')) {
    return 'services-snap2.api.no';
  } else if (host.includes('snap3.api.no')) {
    return 'services-snap3.api.no';
  } else if (host.includes('snap4.api.no')) {
    return 'services-snap4.api.no';
  } else if (host.includes('snap5.api.no')) {
    return 'services-snap5.api.no';
  } else if (host.includes('snap6.api.no')) {
    return 'services-snap6.api.no';
  }
  return 'services.api.no';
};

// Polyfill for Object.fromEntries
if (!Object.fromEntries) {
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        'Object.fromEntries() requires a single iterable argument'
      );
    }
    let obj = {};
    for (let [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

export const getAbTestUrl = (abTestStr, userData) => {
  const parts = abTestStr.split('|');
  if (parts.length != 4) {
    return abTestStr;
  }
  const bRangeStart = parts[2].split('-')[0];
  const bRangeEnd = parts[2].split('-')[1];
  const userGroup = userData?.extraData?.user_grouping_number;
  if (userGroup && userGroup >= bRangeStart && userGroup <= bRangeEnd) {
    return parts[1];
  }
  return parts[0];
};

const renderHTML = (url, publication, userData) => {
  if (url.endsWith('|abtest')) {
    url = getAbTestUrl(url, userData);
  }

  const manifestUrl = `https://${getMnemonicHost()}/api/mnemonic/v2/manifest`;
  const urlAttr = encodeURIComponent(url);

  return `<amedia-include param-publication="${publication}" param-url="${urlAttr}" lazy="true" manifest="${manifestUrl}"></amedia-include>`;
};

const renderSmartEmbed = (teaser, userData) => {
  if (teaser.type !== 'smart-embed' || !teaser.id.startsWith('http')) {
    return teaser;
  }
  const renderedTeaser = {
    id: uuid(),
    type: 'smartembed',
    premium: false,
    html: renderHTML(teaser.id, teaser.publication, userData),
    js: [],
  };

  const layout = getVersions(teaser.layout);
  if (layout) {
    renderedTeaser.layout = layout;
  } else {
    renderedTeaser.unknownHeight = true;
  }

  return renderedTeaser;
};

export default renderSmartEmbed;
