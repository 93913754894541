'use strict';

function isArticle() {
  const pagemodel =
    document.documentElement?.getAttribute('data-pagemodel') || '';
  return ['story', 'feature', 'opinion'].includes(pagemodel);
}

function isInternalTraffic(wwwDomain) {
  return document.referrer.includes(wwwDomain);
}

function getPageData() {
  return JSON.parse(document.querySelector('page-data')?.textContent || '{}');
}

function removeCurrentArticle(teasers, index) {
  teasers.splice(index, 1);
  return teasers;
}

function injectDescription(index, length) {
  const descriptionText = `Saken du har lest er nummer ${
    index + 1
  } av ${length} på forsiden. Fortsett å lese fra sak ${index + 2}:`;
  const descriptionElement = document.createElement('p');
  descriptionElement.appendChild(document.createTextNode(descriptionText));
  descriptionElement.style.fontFamily = 'Open Sans';
  descriptionElement.style.fontStyle = 'italic';
  descriptionElement.style.fontSize = 'larger';
  descriptionElement.style.color = '#000';
  const frontpageElement = document.getElementsByTagName('amedia-frontpage')[0];
  frontpageElement.insertBefore(
    descriptionElement,
    frontpageElement.firstChild
  );
}

function removePrecedingTeasers(teasers, index, userData) {
  const minTeasersLength = 5;
  if (index < teasers.length - minTeasersLength) {
    if (
      userData.extraData.user_grouping_number <= 15 ||
      userData.extraData.amedia_staff
    ) {
      injectDescription(index, teasers.length);
    }
    return teasers.splice(index);
  }
  return teasers;
}

function applyArticleTreatment(teasers, userData) {
  const pageData = getPageData();
  if (
    !pageData.env ||
    !userData ||
    userData.extraData.user_grouping_number >= 32
  ) {
    return teasers;
  }
  const acpId = pageData.data.fields.id;
  let flatTeasers = teasers.flat();
  const currentArticleIndex = flatTeasers.findIndex(
    (item) => item.id === acpId || item.adp?.teaserId === acpId
  );
  if (currentArticleIndex < 0) {
    return teasers;
  }
  flatTeasers = removeCurrentArticle(flatTeasers, currentArticleIndex);
  if (isInternalTraffic(pageData.env.publication.wwwDomain)) {
    flatTeasers = removePrecedingTeasers(
      flatTeasers,
      currentArticleIndex,
      userData
    );
  }
  return flatTeasers;
}

export { isArticle, applyArticleTreatment };
