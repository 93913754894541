'use strict';

function getQueryParam(name) {
  const query = new URLSearchParams(location.search);
  return query.get(name) || '';
}

/*
customer_keys: The user has access through regular subscription
plussalt_subscription: The user has access through plussalt subscription
amedia_staff: The user is an employee
*/
function getNonSubscriberHash(nliHash, user) {
  if (
    !nliHash ||
    nliHash === 'not-in-use' ||
    user.extraData.customer_keys ||
    user.extraData.plussalt_subscription ||
    user.extraData.amedia_staff
  ) {
    return '';
  }

  if (
    location.hostname.includes('www.ao.no') &&
    user.extraData.user_grouping_number <= 39
  ) {
    return nliHash;
  }
  return '';
}

function getRecommendations(user, nliHash) {
  return {
    user_key: user.trackingKey,
    user_grouping_number: user.extraData.user_grouping_number,
    amedia_staff: user.extraData.amedia_staff,
    recommendation_hash: getNonSubscriberHash(nliHash, user) || '',
    uuid: user.uuid,
    plussalt_subscription:
      user.extraData.plussalt_subscription ||
      getQueryParam('plussalt_subscription') === 'true',
    non_sub:
      (!user.extraData.customer_keys &&
        !user.extraData.plussalt_subscription &&
        !user.extraData.amedia_staff) ||
      getQueryParam('non_sub') === 'true',
  };
}

function getRecommendationsBrowserId(id, nliHash) {
  if (nliHash === 'not-in-use') {
    return {};
  }
  return {
    user_key: id,
    recommendation_hash: nliHash,
  };
}

export { getRecommendations, getRecommendationsBrowserId };
