'use strict';

let isDebugEnabled = false;
if (typeof window !== 'undefined') {
  isDebugEnabled = window.location.href.indexOf('debug=true') !== -1;
}

/**
 *  Console logs with a Optimus prefix when ?debug=true is in the URL.
 *  Tip: Add a (Optimus) filter in your developer console to only show the Optimus log.
 */
function debug(type, ...data) {
  if (isDebugEnabled && data.length > 0) {
    data.unshift('Optimus');
    console[type](...data);
  }
}

export default {
  log: debug.bind('log'),
  error: debug.bind('error'),
  info: debug.bind('info'),
};
