'use strict';

import { RPCserver, RPCclient } from '@amedia/frontend-rpc';

const title = document.querySelector('title')?.textContent;

function browserHasBFCache() {
  const iOSVersionArray = navigator.appVersion.match(/OS (\d+)_(\d+)/);
  return (
    !!iOSVersionArray &&
    ((parseInt(iOSVersionArray[1], 10) === 13 &&
      parseInt(iOSVersionArray[2], 10) >= 4) ||
      parseInt(iOSVersionArray[1], 10) > 13)
  );
}

function isDesktopSafari() {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return (
    /Safari/i.test(uA) &&
    /Apple Computer/.test(vendor) &&
    !/Mobi|Android/i.test(uA)
  );
}

let navigationPrevented = false;
const rpcserver = new RPCserver({
  version: '1.0.0',
  component: 'optimus',
});
rpcserver.addListener('preventNavigation', () => {
  navigationPrevented = true;
});
const rpcclient = new RPCclient({
  version: '1.0.0',
  component: 'optimus',
});
rpcclient.addMethod('navigate');

function sendNavigation(url, evt) {
  if (!url || !navigationPrevented) {
    return;
  }
  evt.preventDefault();
  rpcclient.navigate({
    url: new URL(url, document.baseURI).toString(),
  });
}
class OptimusElement extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('click', (evt) => {
      const teaserLinkElement = this.querySelector('.teaser_link');
      if (teaserLinkElement) {
        sendNavigation(teaserLinkElement.getAttribute('href'), evt);
      }
      if (browserHasBFCache() || isDesktopSafari()) {
        return;
      }
      if (this.id) {
        let tivoliRSHeight = 225;
        const amediaIncludeHeights = [];
        if (
          document.getElementsByTagName('tivoli-realestatecarousel').length > 0
        ) {
          tivoliRSHeight = document
            .getElementsByTagName('tivoli-realestatecarousel')[0]
            .getBoundingClientRect().height;
        }

        const amediaIncludes = document.getElementsByTagName('amedia-include');
        if (amediaIncludes.length > 0) {
          [...amediaIncludes].forEach((ai) =>
            amediaIncludeHeights.push({
              height: ai.getBoundingClientRect().height,
              src: ai.getAttribute('src'),
            })
          );
        }
        history.scrollRestoration = 'manual';
        history.replaceState(
          {
            id: this.id,
            offset: this.getBoundingClientRect().top,
            tivoliRSHeight: tivoliRSHeight,
            amediaIncludeHeights: amediaIncludeHeights,
          },
          title
        );
      } else {
        history.scrollRestoration = 'auto';
      }
    });

    const brickTeaser = this.querySelector('brick-teaser');

    brickTeaser?.setAttribute(
      'data-has-access',
      this.getAttribute('has-video-access') ? true : false
    );

    if (this.getAttribute('teaser-type'))
      brickTeaser?.setAttribute(
        'data-teaser-type',
        this.getAttribute('teaser-type')
      );
  }
}

if (!customElements.get('optimus-element')) {
  customElements.define('optimus-element', OptimusElement);
}
