'use strict';

// TODO add weight and multiple sort keys
// const sortBy = ['index']

export default function (teasers) {
  // add meta for groups
  teasers.forEach((item, i) => {
    item.meta = item.meta || {};
    if (Array.isArray(item)) {
      const meta = item.find((m) => m.type === 'meta');
      if (meta) {
        Object.assign(item.meta, meta);
        item.splice(item.indexOf(meta), 1);
      }
      item.meta.type = item.meta.source === 'dredition' ? 'complex' : 'group';

      if (item.meta.source === 'tv2') {
        item.meta.type = 'complex';
        item.meta.source = 'dredition';
      }
    }
    item.meta.index = item.meta.index || i;
  });

  const fullList = teasers
    .sort((a, b) => a.meta.index - b.meta.index)
    .reduce((result, item, i) => {
      if (Array.isArray(item)) {
        result.push(item);
        result.push([]);
        return result;
      }
      if (i === 0) {
        result.push([]);
      }

      const previousGroup = result[result.length - 1];
      previousGroup.push(item);
      return result;
    }, []);

  return function () {
    return fullList.shift() || false;
  };
}
