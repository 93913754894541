function scrollElementIntoView(el, offset) {
  el.scrollIntoView({ block: 'center' });
  const delta = el.getBoundingClientRect().top - offset;
  window.scrollBy(0, delta);
}

function reScrollElementIntoView(el, elementPreviousTop, offset) {
  const newTop = el.getBoundingClientRect().top;
  if (newTop > elementPreviousTop + 100 || newTop < elementPreviousTop - 100) {
    scrollElementIntoView(el, offset);
  }
}

export function restoreScrollPosition() {
  if (!history.replaceState) {
    return;
  }
  const state = history.state;
  if (state && state.id) {
    const el = document.getElementById(state.id);
    if (!el) {
      return;
    }
    const realestateCarousel = document.getElementsByTagName(
      'tivoli-realestatecarousel'
    )[0];

    const amediaIncludes = document.getElementsByTagName('amedia-include');
    if (amediaIncludes && state.amediaIncludeHeights) {
      [...amediaIncludes].forEach((ai) => {
        state.amediaIncludeHeights.every((aiHeight) => {
          if (ai.getAttribute('src') === aiHeight.src) {
            ai.style.minHeight = `${aiHeight.height}px`;
            return false;
          }
          return true;
        });
      });
    }
    if (realestateCarousel) {
      if (state.tivoliRSHeight && state.tivoliRSHeight > 225) {
        realestateCarousel.style.height = `${state.tivoliRSHeight}px`;
      }
    }
    const offset = state.offset;
    // Delay before clicked element is scrolled into view, so the page has time to draw
    setTimeout(() => {
      scrollElementIntoView(el, offset);
      const elementTop = el.getBoundingClientRect().top;
      let hasScrolled = false;
      setTimeout(() => {
        // Do not rescroll element into view if user starts scrolling to avoid jumping
        window.addEventListener(
          'scroll',
          () => {
            hasScrolled = true;
          },
          { once: true }
        );

        // has element moved because of slow page rendring
        setTimeout(() => {
          if (!hasScrolled) {
            reScrollElementIntoView(el, elementTop, offset);
          }
        }, 1500);
      }, 1000);
    }, 600);
  }
}
