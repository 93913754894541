'use strict';

import { layouts } from './layout-definitions.js';

const animations = [];

function getAnimations() {
  return animations;
}

function createTeaserStyles(containerInfo, breakpoint) {
  breakpoint.rowCounter = breakpoint.rowCounter || 0;
  const rows = containerInfo.content
    .reduce((r, teaserContainer) => {
      if (r.indexOf(teaserContainer.top) === -1) {
        r.push(teaserContainer.top);
      }
      return r;
    }, [])
    .sort((a, b) => a - b);

  let floatStyle = '';
  let lastBreakpointStyle = '';
  let conditionalStyles = '';

  const templateRows = rows.reduce(
    (pv, cv, i, arr) =>
      `${pv} ${
        i === arr.length - 1 && arr.length > 1 ? 'min-content' : 'max-content'
      }`,
    ''
  );
  breakpoint.rows = `${breakpoint.rows || ''} ${templateRows}`;

  containerInfo.columns.forEach((column) => {
    column.forEach((teaserContainer, i) => {
      const teaserInfo = teaserContainer.teaser.getInfo();
      const teaserId = teaserContainer.teaser.id;
      const teaserType = teaserContainer.teaser.type;
      const styles = teaserContainer.teaser.getStyles();
      const rowStart =
        breakpoint.rowCounter + rows.indexOf(teaserContainer.top) + 1;
      const rowEnd =
        breakpoint.rowCounter +
        (column[i + 1] ? rows.indexOf(column[i + 1].top) + 1 : 1 + rows.length);
      const columnStart =
        1 +
        Math.floor(
          (teaserContainer.left / breakpoint.width) *
            layouts[breakpoint.width].columns
        );
      const columnEnd =
        1 +
        Math.floor(
          ((teaserContainer.left + teaserInfo.width) / breakpoint.width) *
            layouts[breakpoint.width].columns
        );

      if (styles.animations) {
        if (animations.indexOf(styles.animations) === -1) {
          animations.push(styles.animations);
        }
      }

      if (styles.mediaqueries) {
        conditionalStyles += styles.mediaqueries;
      }

      if (styles.common) {
        floatStyle += styles.common;
      }

      const gridColumnEnd =
        columnEnd < layouts[breakpoint.width].columns
          ? columnEnd + 1
          : columnEnd;

      floatStyle += `
      #art-${teaserId} {
          grid-column-start: ${columnStart};
          grid-column-end: ${teaserInfo.width ? gridColumnEnd : '-1'};
          grid-row-start: ${rowStart};
          grid-row-end: ${rowEnd};
      }`;

      floatStyle += `
      @media screen and (max-width: 532px) {
        #art-${teaserId} {
            grid-column-end: ${teaserInfo.width ? gridColumnEnd : 'auto'};
        }
      }`;
      if (teaserType === 'smartembed') {
        lastBreakpointStyle += `
                #art-${teaserId} {
                  overflow: hidden;                
                }
                `;
        floatStyle += `
                #art-${teaserId} {
                    overflow: hidden;
                }
                `;
      }

      if (teaserInfo) {
        floatStyle += `
        #art-${teaserId} .titleWrapper > span {
            font-size: ${teaserInfo.fontSize}em;
        }
        #art-${teaserId} .teaser_image img.amedia-img-full {
            top: ${-teaserInfo.imageTop || 0}%;
            left: ${-teaserInfo.imageLeft || 0}%;
            width: ${teaserInfo.imageWidthPercent || 100}%;
            height: auto;
        }
        `;

        if (teaserType === 'smartembed') {
          lastBreakpointStyle += `
                #art-${teaserId} {
                    min-height: ${teaserInfo.height}px;
                }
                `;
          floatStyle += `
                #art-${teaserId} {
                    min-height: ${
                      (teaserInfo.height /
                        (containerInfo.width +
                          breakpoint.styles.hPadding * 2)) *
                      100
                    }vw;
                }
                `;
        }

        if (teaserInfo.verticalPadding) {
          const padding =
            ((teaserInfo.verticalPadding / teaserInfo.width) * 100 + 6) / 2;
          floatStyle += `
                #art-${teaserId} .with-background-color .body,
                #art-${teaserId} .no-image .body {
                    padding-top: ${padding}% !important;
                    padding-bottom: ${padding}% !important;
                }
                `;
        }
        if (teaserInfo.imageHeight && teaserInfo.version === 'default') {
          //Temporary fix for missing image width. Will probably be replaced by aspect-ratio
          floatStyle += `
                #art-${teaserId} .imagewrapper {
                    padding-top: ${
                      (teaserInfo.imageHeight / teaserInfo.width) * 100
                    }% !important;
                }
                `;
        }
      }
    });
  });

  let css = `
    @media screen and (min-width: ${breakpoint.styles.minWidth}px) and (max-width:${breakpoint.styles.maxWidth}px) {
        ${floatStyle}
    }
    `;

  if (breakpoint.styles.lastBreakpoint) {
    css += `@media screen and (min-width:${breakpoint.styles.maxWidth}px) {
            ${floatStyle}
            ${lastBreakpointStyle}
        }`;
  }
  css += conditionalStyles;

  breakpoint.rowCounter += rows.length;
  return css;
}

function getGridCss({ breakpoint, id, useStaticGrid }) {
  let css;
  const useMobileStaticGrid = breakpoint.styles.minWidth === 0 && useStaticGrid;
  if (useMobileStaticGrid) {
    css = getMobileStaticGridCss(breakpoint, id);
  } else {
    css = `
    @media screen and (min-width: ${breakpoint.styles.minWidth}px) and (max-width:${breakpoint.styles.maxWidth}px) {
        #${id} {
            grid-template-columns: repeat(10, 1fr);
            grid-column-gap: var(--brick-space-deckXGapL);
            grid-row-gap: var(--brick-space-deckYGapL);
            margin-bottom: var(--brick-space-deckYGapL);
        }
    }`;
  }

  if (breakpoint.styles.lastBreakpoint) {
    css += `
        @media screen and (min-width:${breakpoint.styles.maxWidth}px) {
            #${id} {
              grid-template-columns: repeat(10, 1fr);
                grid-column-gap: var(--brick-space-deckXGapL);
                grid-row-gap: var(--brick-space-deckYGapL);
                margin-bottom: var(--brick-space-deckYGapL);
            }
        }
        `;
  }
  return css;
}

function getMobileStaticGridCss(breakpoint, id) {
  return `
    @media screen and (max-width: 532px) {
        #${id} {
            grid-template-columns: auto;
            grid-template-rows: ${breakpoint.rows};
            grid-column-gap: var(--brick-space-deckXGapM);
            grid-row-gap: var(--brick-space-deckYGapM);
            margin-bottom: var(--brick-space-deckYGapM);
        }
    }`;
}

function createFrontStyle(breakpoint, id, isGroup, useStaticGrid) {
  const columns = Array(layouts[breakpoint.width].columns)
    .fill(
      `${(layouts[breakpoint.width].column / breakpoint.width) * 100}%`,
      0,
      layouts[breakpoint.width].columns
    )
    .join(' ');
  let css = getGridCss({ columns, breakpoint, id, useStaticGrid });

  css += `
    #${id} {
        display: grid;
        padding-top: 0;
    }`;
  breakpoint.rowCounter = 0;
  return css;
}

function createFrontUnknownHeightStyle(id) {
  return `
    #${id} {
        display: block;
        padding-top:0;
    }`;
}

export {
  getAnimations,
  createTeaserStyles,
  createFrontStyle,
  createFrontUnknownHeightStyle,
};
