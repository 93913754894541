const layoutsDefault = () => ({
  500: { teaserSizes: [500, 276, 212] },
  980: { teaserSizes: [980, 780, 680, 580, 480, 380, 280] },
});

export const getVersions = (layoutProps) => {
  let layoutVersion;
  const layoutWidths = Object.keys(layoutProps.dimensions || {})
    .map((key) => parseInt(key, 10))
    .sort((a, b) => a - b);

  if (layoutWidths.length > 0) {
    const layout = layoutsDefault();
    layoutVersion = Object.keys(layout).reduce((result, breakpoint) => {
      result[breakpoint] = layout[breakpoint].teaserSizes
        .filter((size) => {
          if (!layoutProps.width) {
            return true;
          }
          return !(
            layoutProps.width.min > size || layoutProps.width.max < size
          );
        })
        .map((size) => {
          const dimensionKey = layoutWidths.filter((w) => w <= size).pop();
          const dimensions =
            layoutProps.dimensions[dimensionKey] || layoutProps.dimensions[0];
          if (!dimensions) {
            return null;
          }
          return {
            width: size,
            versions: {
              default: [
                {
                  styles: [],
                  height: dimensions.ratio
                    ? (dimensions.ratio / 100) * size
                    : dimensions.height,
                },
              ],
            },
          };
        })
        .filter((item) => !!item);
      return result;
    }, {});
  }
  return layoutVersion;
};
