'use strict';

import { Container } from './container.js';
import { Teaser } from './teaser.js';
import {
  createTeaserStyles,
  createFrontStyle,
  createFrontUnknownHeightStyle,
  getAnimations,
} from './grid-css.js';
import { drEditionColorMapping } from './color-mapping.js';
import { getLayout } from './layout-definitions.js';
import { brickThemes, createSonar } from './create-sonar.js';

const currentLayout = getLayout();

let optimusElementIndex = 0;

function closeContainer(breakpoint, unknownHeight) {
  const containerInfo = breakpoint.container.fill();
  const styles = createTeaserStyles(containerInfo, breakpoint);
  breakpoint.styles.compositions.push(containerInfo.compositions);
  breakpoint.styles.currentPageYPosition +=
    breakpoint.container.getHeight() + breakpoint.styles.vPadding;
  breakpoint.index += 1;
  if (unknownHeight) {
    return '';
  }

  return styles;
}

function addTeaserPositionToHTML(html, index) {
  return html.replace(
    '<meta',
    `<meta itemprop="position" content="${index}" /><meta`
  );
}

function createContainer(breakpoint, ltr) {
  breakpoint.container = new Container({
    type: breakpoint.width,
    ltr,
    index: breakpoint.container.index + 1,
    vPadding: breakpoint.styles.vPadding,
    hPadding: breakpoint.styles.hPadding,
    compositions: breakpoint.styles.compositions,
  });
}

function createPageHeader({ title, breakingNews, theme }) {
  let html = '<header><h2>';
  if (breakingNews) {
    html += createSonar(theme);
  }
  html += `${title}</h2></header>`;
  return html;
}

function createLargePageHeader({ title, breakingNews, theme, largeTitleCss }) {
  let html = `<header><h2 class="${largeTitleCss}">`;
  if (breakingNews) {
    html += createSonar(theme);
  }
  html += `${title}</h2></header>`;
  return html;
}

class PageLayout {
  constructor({ id, breakpoints, options, theme }) {
    this.html = '';
    this.frontCSS = '';
    this.articleCSS = '';
    this.jsExternal = [];
    this.id = id;
    this.breakpoints = [];
    this.pageHeight = {};
    this.theme = theme;
    Object.keys(options).forEach((key) => {
      this[key] = options[key];
    });

    let currentMinBreakpointWidth = 0;
    this.breakpoints = breakpoints
      .sort((a, b) => a.width - b.width)
      .map((breakpoint, i) => {
        const maxWidth = breakpoint.width + breakpoint.hPadding * 2;
        const lastBreakpoint = i === breakpoints.length - 1;
        const minWidth = currentMinBreakpointWidth;
        currentMinBreakpointWidth = maxWidth + 1;
        return {
          container: new Container({
            type: breakpoint.width,
            ltr: true,
            index: this.containerIndex[breakpoint.width] || 0,
            vPadding: breakpoint.vPadding,
            hPadding: breakpoint.hPadding,
            compositions: [],
          }),
          width: breakpoint.width,
          styles: {
            minWidth,
            maxWidth,
            ltr: true,
            vPadding: breakpoint.vPadding,
            hPadding: breakpoint.hPadding,
            lastBreakpoint,
            alterDirection: breakpoint.alterDirection,
            currentPageYPosition: 0,
            compositions: [],
          },
          index: options.index || 0,
        };
      });
  }

  close() {
    const useStaticGrid = brickThemes.includes(this.theme);
    const isDrEditionGroup =
      this.group && this.source === 'dredition' && this.type === 'complex';
    this.breakpoints = this.breakpoints
      .map((breakpoint) => {
        this.articleCSS += closeContainer(breakpoint, this.unknownHeight);
        this.frontCSS += createFrontStyle(
          breakpoint,
          this.id,
          isDrEditionGroup,
          useStaticGrid
        );
        return breakpoint;
      })
      .sort((a, b) => b.width - a.width);

    if (this.unknownHeight) {
      this.frontCSS = createFrontUnknownHeightStyle(this.id);
    }

    this.articleCSS += getAnimations().join('');

    const css = `
        <style type="text/css" data-desc="Article styles for ${this.id}">${this.articleCSS}</style>
        <style type="text/css" data-desc="Front styles for ${this.id}">${this.frontCSS}</style>
        `;

    let html = `<div id="${this.id}" class="front">${this.html}</div>`;

    if (this.group && this.type === 'complex') {
      let title = '';
      let classes = 'optimus-complex-front';

      if (this.title && this.largeTitle === true) {
        const largeTitleCss = 'large';
        title = createLargePageHeader({
          title: this.title,
          breakingNews: this.breakingNews,
          theme: this.theme,
          largeTitleCss,
        });
      } else if (this.title && this.largeTitle === false) {
        title = createPageHeader({
          title: this.title,
          breakingNews: this.breakingNews,
          theme: this.theme,
        });
      } else {
        classes += ' noHeader';
      }
      if (this.backgroundColor) {
        classes += ` optimus-${drEditionColorMapping[this.backgroundColor]}`;
      }
      html = `<section class="${classes}">${title}${html}</section>`;
    }

    html = `${css}${html}`;
    return {
      html,
      jsExternal: this.jsExternal,
    };
  }

  add(teaserList, onlyFillContainer) {
    let done = false;
    return teaserList.filter((teaserData) => {
      if (done) {
        return true;
      }
      if (!teaserData) {
        return false;
      }
      if (teaserData.type === 'multivariant') {
        return false;
      }

      const teaser = new Teaser(teaserData);

      let breakpoints = this.breakpoints.slice();

      if (onlyFillContainer) {
        breakpoints = breakpoints.sort((a, b) => {
          if (a.width === onlyFillContainer) {
            return -1;
          }
          if (b.width === onlyFillContainer) {
            return 1;
          }
          return 0;
        });
      }

      breakpoints.forEach((breakpoint) => {
        if (!done) {
          const isAdded = breakpoint.container.add(teaser, this.type);

          if (!isAdded && breakpoint.width === onlyFillContainer) {
            done = true;
          } else if (!isAdded) {
            this.articleCSS += closeContainer(breakpoint, this.unknownHeight);
            this.containerIndex[breakpoint.width] = breakpoint.container.index;

            if (
              breakpoint.styles.alterDirection &&
              breakpoint.container.content.length > 1
            ) {
              breakpoint.styles.ltr = !breakpoint.styles.ltr;
            }
            createContainer(breakpoint, breakpoint.styles.ltr);
            breakpoint.container.add(teaser, this.type);
          }
        }
      });
      if (!done) {
        let attr = '';
        if (
          teaser.breakpoints &&
          teaser.breakpoints[currentLayout] &&
          teaser.breakpoints[currentLayout].active
        ) {
          if (!teaser.breakpoints[currentLayout].active.lineCount) {
            attr += `unknown-teaser-content="true"`;
          } else {
            attr += `linecount="${teaser.breakpoints[currentLayout].active.lineCount}" `;
          }
          attr += `version="${teaser.breakpoints[currentLayout].active.version}" `;
          attr += `width="${teaser.breakpoints[currentLayout].active.width}" `;
          attr += `index="${optimusElementIndex}"`;
        }

        attr += teaserData?.hasVideoAccess
          ? `has-video-access="${teaserData.hasVideoAccess}"`
          : '';

        attr += teaserData?.teaserType
          ? `teaser-type="${teaserData.teaserType}"`
          : '';

        if (teaserData.experimentDebug) {
          attr += ' style="outline: red solid 5px;"';
        }

        optimusElementIndex += 1;
        let teaserHtml = teaserData.html;
        if (teaserHtml) {
          const match = teaserHtml.match(
            /<a href="(https:\/\/www.alt.no[^"]*)"/
          );
          if (match) {
            teaserHtml = teaserHtml.replace(
              /<a href="[^"]*"/,
              `<a href="${match[1]}?amedia_referrer=${location.host}&infobox"`
            );
          }
          teaserHtml = addTeaserPositionToHTML(teaserHtml, optimusElementIndex);
        }
        this.html += `<optimus-element id="art-${teaserData.id}" ${attr}>${teaserHtml}</optimus-element>`;
        if (teaserData.js) {
          this.jsExternal = this.jsExternal.concat(teaserData.js);
        }
      }
      return done;
    });
  }
}

export default PageLayout;
