function rewriteNarrowWide(src) {
  const viewWidth = Math.min(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  if (viewWidth < 768) {
    return src.replace('/wide', '/narrow');
  }
  return src;
}

function shouldPersonalize() {
  const query = new URLSearchParams(window.location.search);
  const hasPersonalizedParam = query.has('personalized');

  if (!hasPersonalizedParam) {
    return true;
  }

  return query.get('personalized') === 'true';
}

export async function fetchData(src, token, recommendations = {}) {
  let srcUrl = src;
  let fetchOptions = {};
  let queryParams = {
    token,
    ts: Date.now(),
  };
  if (src.includes('/ordino')) {
    srcUrl = rewriteNarrowWide(src);

    if (recommendations.uuid) {
      fetchOptions = {
        headers: {
          'user-uuid': recommendations.uuid,
        },
      };
    }
    const personalized = shouldPersonalize();
    queryParams = {
      ...queryParams,
      ...(recommendations.plussalt_subscription && {
        plussalt_subscription: true,
      }),
      ...(recommendations.user_key && {
        user_key: recommendations.user_key,
      }),
      ...(recommendations.recommendation_hash && {
        recommendation_hash: recommendations.recommendation_hash,
      }),
      ...(recommendations &&
        recommendations.user_grouping_number && {
          user_group: recommendations.user_grouping_number,
        }),
      ...(recommendations &&
        recommendations.amedia_staff && {
          amedia_staff: recommendations.amedia_staff,
        }),
      ...(recommendations &&
        recommendations.non_sub && {
          non_sub: recommendations.non_sub,
        }),
      ...(!personalized ? { personalized: false } : {}),
    };
  }
  if (srcUrl && srcUrl.startsWith('/')) {
    srcUrl = `https://${document.domain}${srcUrl}`;
  }
  const url = new URL(srcUrl);
  Object.keys(queryParams).forEach((name) =>
    url.searchParams.append(name, queryParams[name])
  );
  return fetch(url.toString(), fetchOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(
          `Failed to fetch ${src}. Status code ${res.statusCode}. Status text ${res.statusText}`
        );
      }
      return res.json();
    })
    .then((data) => {
      data.queryParams = queryParams;
      return data;
    });
}
